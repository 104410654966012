import "./style.css"
const NavBar = () =>{
    return (
        <div className={"navbarWrapper"}>
            <div >
                <p className={"titleWrapper"}>Mahmoud Arab</p>
                <p className={"title"}>Software engineer / ML engineer</p>
            </div>

        </div>
    )
}

export default NavBar;